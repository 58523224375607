<template>
    <div class="boxs" :style="'width:'+ width +';height:'+ height">
        <img :src=" vlogImgName(item.invitationImg) " alt="">
        <div class="infos">
            <p class="vlog-tit two-txt-cut">{{ item.invitationTitle || '' }}</p>
            <div class="info-user f-row-s-c">
                <img class="user-img" :src=" item.publishImg ">
                <p class="names">{{ item.publishName || '' }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VhomeVlog",
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        width: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        },
    },
    methods: {
        vlogImgName(list) {
            return list ? list.split(',')[0] : ''
        },
    }
}
</script>

<style lang="scss" scoped>
.boxs {
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.infos {
    position: absolute;
    bottom: 10px;
    left: 10px;

    .vlog-tit {
        margin-bottom: 6px;
        line-height:26px;
        font-size: 16px;
        color: #fff;
    }

    .info-user {
        .user-img {
            margin-right: 4px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: cover;
        }

        .names {
            font-size: 12px;
            color: #fff;
        }
    }
}
</style>