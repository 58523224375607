<template>
  <div>
    <v-header showStyle="null"></v-header>

    <div class="page-box">
      <v-swiper pagination navigation>
        <v-swiper-slide v-for="image in imgList" :key="image.id">
          <img :src="image.img" class="swiper-imgs" />
        </v-swiper-slide>
      </v-swiper>

      <div class="info-box f-row-b-c">
        <div class="l-info">
          <div class="l-tit">中文包车畅游全世界</div>
          <div class="l-txt">10万+当地华人司导服务，按天包车、接送机、单次用车</div>
          <ul class="l-ul f-row-s-c">
            <li class="one-li" @click="goLink(1)">iphone版下载</li>
            <li class="two-li" @click="goLink(2)">Android版下载</li>
          </ul>
        </div>
        <div class="r-imgs">
          <img src="../../../public/img/upload_app.png" alt="">
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      imgList: [
        {
          "id": 1,
          "img": require('../../../public/img/upload_bg.png')
        }
      ],
      uploadList: [{
        id: '1',
        name: 'iphone版下载',
        img: require('../../../public/img/iphone.png'),
        hoverImg: require('../../../public/img/iphone_h.png'),
      }, {
        id: '2',
        name: 'Android版下载',
        img: require('../../../public/img/android.png'),
        hoverImg: require('../../../public/img/android_h.png'),
      }],
    }
  },
  methods: {
    goLink(type) {
      if (type == 1) {
        //ios
        this.$message.success('敬请期待')
        return
      } else if (type == 2) {
        //android
        window.open('https://www.pgyer.com/F3Zb0F2r')
      }
    }
  }
}
</script>
  
<style lang="scss" scoped>
.page-box {
  position: relative;
}

.swiper-imgs {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.info-box {
  position: absolute;
  top: 116px;
  left: 0;
  right: 0;
  width: 1200px;
  margin: auto;
  z-index: 1;
}

.l-info {
  width: 590px;

  .l-tit {
    margin-bottom: 10px;
    width: 450px;
    line-height: 100px;
    font-weight: bold;
    font-size: 72px;
    color: #333;
  }

  .l-txt {
    margin-bottom: 70px;
    line-height: 38px;
    font-size: 28px;
    color: #333;
  }

  .l-ul {


    li {
      margin-right: 52px;
      padding-left: 60px;
      width: 252px;
      height: 64px;
      line-height: 64px;
      font-size: 24px;
      color: #333;
      border: 1px solid #333;
      border-radius: 4px;
      cursor: pointer;

      &.one-li {
        background: url('../../../public/img/iphone.png') no-repeat left center;
        background-position: 20px;
      }

      &.two-li {
        background: url('../../../public/img/android.png') no-repeat left center;
        background-position: 20px;
      }

      &:hover {
        color: #fff;
        border-color: var(--theme-color);

        &.one-li {
          background: var(--theme-color) url('../../../public/img/iphone_h.png') no-repeat left center;
          background-position: 20px;
        }

        &.two-li {
          background: var(--theme-color) url('../../../public/img/android_h.png') no-repeat left center;
          background-position: 20px;
        }
      }
    }
  }
}
</style>