<template>
    <div class="foot-box">

        <div class="foot-top">
            <div class="wrap f-row-s-c h100">
                <div class="txts">远山旅游</div>
                <div class="txts">旅行服务</div>
            </div>
        </div>

        <div class="foot-bottom wrap f-row-b-c">
            <div class="l-foot f-row-s-c">
                <div class="list-box f-row-s-c f-w" style="margin-right:150px;">
                    <div class="list-item" v-for="(item, index) in oneList" :key="index" @click="goLinkLeft(item)">
                        {{ item.name }}
                    </div>
                </div>
                <div class="list-box f-row-s-c f-w">
                    <div class="list-item" v-for="(item, index) in twoList" :key="index" @click="goLink(item)">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="r-foot f-row-e-c">
                <div class="code-box f-col-c-c">
                    <img src="../../public/code_app.png" alt="">
                    <span>远山APP扫码下载</span>
                </div>
                <div class="code-box f-col-c-c">
                    <img src="../../public/code_gzh.png" alt="">
                    <span>远山公众服务号</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VhomeFoot",
    data() {
        return {
            oneList: [{
                id: '1',
                name: '关于远山',
                url: ''
            }, {
                id: '2',
                name: '联系我们',
                url: ''
            }, {
                id: '3',
                name: '隐私政策',
                url: ''
            }, {
                id: '4',
                name: '服务协议',
                url: ''
            }, {
                id: '5',
                name: '60000个细分目的地新玩法',
                url: ''
            }],
            twoList: [{
                id: '1',
                name: '专车服务',
                url: '/taxi'
            }, {
                id: '2',
                name: '汽车租赁',
                url: '/carRental'
            }, {
                id: '3',
                name: '旅游产品',
                url: '/travelList'
            },
            //  {
            //     id: '4',
            //     name: '接送机',
            //     url: '/transfer'
            // },
             {
                id: '5',
                name: '单次包车',
                url: '/Bcar'
            }],
        }
    },
    methods: {
        goLinkLeft(item) {
            if (item.id == 3) {
                this.$router.push({ path: '/agreement', query: { type: 2 } })
            } else if (item.id == 4) {
                this.$router.push({ path: '/agreement', query: { type: 1 } })
            }
        },
        goLink(item) {
            this.$router.push(item.url, () => { }, () => { })
        },
    }
}
</script>

<style lang="scss" scoped>
.foot-box {
    min-height: 182px;
    background: #202020;
}

.foot-top {
    height: 64px;
    font-size: 20px;
    color: #dae0f3;
    border-bottom: 1px solid #2a2a2a;

    .txts {
        width: 344px;
    }
}

.foot-bottom {
    padding: 14px 0;

    .list-box {
        width: 194px;
    }

    .list-item {
        margin-right: 25px;
        line-height: 30px;
        font-size: 14px;
        color: #aeaeae;
        cursor: pointer;
    }

    .code-box {
        margin-left: 30px;

        img {
            width: 68px;
            height: 68px;
            background: #fff;
        }

        span {
            margin-top: 10px;
            font-size: 12px;
            color: #aeaeae;
        }
    }
}

.item-tit {
    margin-bottom: 30px;
    font-size: 18px;
    color: #333;

    .line {
        flex: 0 0 4px;
        margin-right: 8px;
        width: 4px;
        height: 18px;
        background: var(--theme-color);
    }
}

.imgs {
    flex: 0 0 222px;
    margin-right: 26px;
    width: 222px;
    height: 152px;

}

.infos {
    min-height: 152px;

    .info-con {
        line-height: 24px;
        font-size: 14px;
        color: #333;
    }

    .info-user {
        .user-img {
            margin-right: 8px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
        }

        .user-txt {
            .names {
                margin-bottom: 4px;
                font-size: 14px;
                color: #333;
            }

            .times {
                font-size: 12px;
                color: #666;
            }
        }
    }
}
</style>