<template>
    <div>
        <div class="img-box">
            <img :src="item.desImg || require('../../public/logo_default.png')">
        </div>
        <div class="info-box">
            <p class="infos one-txt-cut">{{ item.desName || '' }}</p>
            <div v-if="showPage == 'car'" class="prices">￥{{ item.desMoney || 0.00 }}/天</div>
            <div v-else class="prices">￥{{ item.desMoney || 0.00 }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VCard',
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        showPage: {
            type: String,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>

.img-box {
    height: 188px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.info-box {
    padding:8px;

    .infos {
        line-height: 30px;
        font-size: 16px;
        color: #000;
    }

    .prices {
        line-height: 22px;
        font-weight: bold;
        font-size: 16px;
        color: #F00;
    }
}
</style>