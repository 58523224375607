<template>
  <div>
    <v-header showStyle="white"></v-header>
    <div class="bg-box">
      <div class="wrap f-row-c-s">
        <v-sidebar></v-sidebar>
        <div class="w100">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'centre',
  data() {
    return {}
  },
  methods: {},
}
</script>
  
<style scoped lang="scss"></style>