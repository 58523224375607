<template>
	<div>
		<v-header showStyle="white"></v-header>
		<div class="bg-box">
			<div class="wrap contain-box">

				<div class="main-title f-row-s-c">
					<div class="line"></div>专车服务
				</div>

				<div class="card-box">
					<div class="card-tit f-row-s-c">
						<img src="../../../public/img/order1.png" alt="">出发地
					</div>
					<div class="address-box">
						<v-address width="634px" @inputValue="inputValueStart" placeholder="出发地详细地址"></v-address>
					</div>
				</div>

				<div class="card-box">
					<div class="card-tit f-row-s-c">
						<img src="../../../public/img/order1.png" alt="">到达地
					</div>
					<div class="address-box">
						<v-address width="634px" @inputValue="inputValueEnd" placeholder="到达地详细地址"></v-address>
					</div>
				</div>

				<div class="card-box">
					<div class="card-tit f-row-s-c">
						<img src="../../../public/img/index_icon_renshu.png" alt="">乘客数
					</div>
					<div class="inputs" style="width:634px;">
						<el-input v-model="form.carPerson" placeholder="请输入乘客数" clearable></el-input>
					</div>
				</div>

				<div class="card-box">
					<div class="card-tit f-row-s-c">
						<img src="../../../public/img/order2.png" alt="">出发时间
					</div>
					<div class="time-box f-row-s-c">
						<div class="common-times f-row-b-c">
							<el-date-picker type="date" v-model="form.date" prefix-icon="0" placeholder="请选择出发日期"
								value-format="yyyy-MM-dd" clearable>
							</el-date-picker>
							<i class="el-icon-arrow-down"></i>
						</div>
						<div class="common-times f-row-b-c">
							<el-time-picker v-model="form.time" prefix-icon="0" placeholder="请选择出发时间" value-format="HH:mm"
								format="HH:mm" clearable>
							</el-time-picker>
							<i class="el-icon-arrow-down"></i>
						</div>
					</div>
				</div>

				<div class="operate-box">
					<button class="btn" @click="goCalculate">查询价格</button>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import $api from '@/api/api.js'
export default {
	name: 'taxi',
	data() {
		return {
			form: {
				startAddress: '',//出发地
				endAddress: '',//到达地
				carPerson: '',//乘客数
				date: '', //出发日期
				time: '', //出发时间
			},
		}
	},
	created() {
		this.getCarTimeList()
	},
	methods: {
		//赋值出发地地址
		inputValueStart(e) {
			this.form.startAddress = e;
		},
		//赋值结束地地址
		inputValueEnd(e) {
			this.form.endAddress = e;
		},
		//计算
		goCalculate() {
			if (!this.form.startAddress) {
				this.$message.error('请输入出发地地址')
				return
			}
			if (!this.form.endAddress) {
				this.$message.error('请输入到达地地址')
				return
			}
			if (!this.form.carPerson) {
				this.$message.error('请输入乘客数')
				return
			}
			if (!this.form.date) {
				this.$message.error('请选择出发日期')
				return
			}
			if (!this.form.time) {
				this.$message.error('请选择出发时间')
				return
			}
			const loading = this.$loading('loading-box', '计算中...', true, 'el-icon-loading', 'rgba(0, 0, 0, 0.8)')
			//计算距离
			$api.distanceCalculation({
				originsAddress: this.form.startAddress,
				destinationsAddress: this.form.endAddress,
			}).then(res => {
				loading.close()
				if (res.code == 200) {
					this.$message.success('计算成功')
					let datas = res.data || {}
					let dataParams = {}
					dataParams = {
						orderTypeCode: 'SPECIAL_CAR_SERVICE',
						setOutAddress: this.form.startAddress,
						arriveOutAddress: this.form.endAddress,
						numberOfPassengers: this.form.carPerson,
						appointmentTime: this.form.date + ' ' + this.form.time + ':00',
						travelDistance: datas.kilometers || '0',
						toll: datas.toll || '0',
					}
					localStorage.setItem('orderInfo', encodeURIComponent(JSON.stringify(dataParams)))
					this.$router.push({
						path: '/carList', query: { type: '4' }
					})
				} else {
					this.$message.error(res.msg)
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.inputs {
	padding: 0 14px;
	width: 328px;
	height: 50px;
	border: 1px solid #DCDFE6;
	border-radius: 4px;
	box-sizing: border-box;
}

.contain-box {
	padding: 30px 46px;
}

.card-tit {
	padding: 30px 0 20px;
	font-size: 18px;
	color: #333;

	img {
		margin-right: 8px;
		max-width: 18px;
		width: auto;
	}
}

.car-box {
	.car-tips {
		display: inline-block;
		margin-top: 20px;
		padding: 0 20px;
		line-height: 28px;
		font-size: 14px;
		color: #FE4E00;
		background: #fffaf7;
		border-radius: 4px;
	}
}

.operate-box {
	padding: 50px 0 100px;
	width: 632px;
}

.price-box {
	height: 74px;
	background: #f3f3f3;
	border-radius: 4px;
	overflow: hidden;

	.l-price {
		padding-left: 18px;
		font-size: 18px;
		color: #333;

		.nums {
			font-size: 14px;
			color: #fa692a;

			span {
				font-weight: bold;
				font-size: 20px;
			}
		}
	}

	.r-price {
		width: 220px;
		height: 100%;
		font-size: 22px;
		color: #fff;
		background: var(--theme-color);
		cursor: pointer;
	}
}
</style>