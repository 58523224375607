<template>
    <div>
        <div class="item-tit f-row-s-c">
            <div class="line"></div>
            <p class="one-txt-cut">{{ item.invitationTitle || '' }}</p>
        </div>
        <div class="item-info f-row-s-c">
            <img class="imgs" :src="getImgList(item.invitationImg)[0]">
            <div class="infos f-col-b-s flex1">
                <p class="info-con four-txt-cut" v-html="item.invitationContext"></p>
                <div class="info-user f-row-e-c w100">
                    <img class="user-img" :src="item.publishImg || require('../../public/img/avatar.png')">
                    <div class="user-txt f-col-c-b">
                        <p class="names">{{ item.publishName || '' }}</p>
                        <p class="times">{{ disposeTime(item.createTime) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VvlogItem",
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
    },
    methods: {
        getImgList(list) {
            return list ? list.split(',') : ''
        },
        disposeTime(time) {
            let a = time ? time.slice(0, -10) : ''
            return a.replace('T', ' ')
        },
    }
}
</script>

<style lang="scss" scoped>
.item-tit {
    margin-bottom: 30px;
    font-size: 18px;
    color: #333;

    .line {
        flex: 0 0 4px;
        margin-right: 8px;
        width: 4px;
        height: 18px;
        background: var(--theme-color);
    }
}

.imgs {
    flex: 0 0 222px;
    margin-right: 26px;
    width: 222px;
    height: 152px;

}

.infos {
    min-height: 152px;

    .info-con {
        line-height: 24px;
        font-size: 14px;
        color: #333;
    }

    .info-user {
        .user-img {
            margin-right: 8px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
        }

        .user-txt {
            .names {
                margin-bottom: 4px;
                font-size: 14px;
                color: #333;
            }

            .times {
                font-size: 12px;
                color: #666;
            }
        }
    }
}
</style>