import { $http } from '../plugins/axios'
const { get, post } = $http

export default {
    //新版
    getAreaCodeList: (params) => get(`/fangshan/dm-order/listAllCode`, params), //手机号区号列表
    pageAllCarService: (params) => get(`/api/system/enterpriseUser/pageAllCarService`, params), //平台服务信息--企业
    distanceCalculation: (params) => get(`/system/enterpriseOrder/distanceCalculation`, params), //计算距离 专车
    pageAllUserCarByAddress: (params) => get(`/fangshan/dm-user/pageAllUserCarByAddress`, params), //分页车型信息列表 专车
    pageAllUserCarByTime: (params) => get(`/fangshan/dm-user/pageAllUserCarByTime`, params), //分页车型信息列表 包车
    
    // pageAllUserCarByAddress: (params) => get(`/api/system/enterpriseUser/pageAllCarByAddress`, params), //分页车型信息列表 专车
    // pageAllUserCarByTime: (params) => get(`/api/system/enterpriseUser/pageAllCarByTime`, params), //分页车型信息列表 包车

    //公共
    getCpc: (params) => get(`/fangshan/dm-new/cityList`, params), //国家/省市区--下拉
    listTime: (params) => get(`/fangshan/dm-user/listTime`, params), //用车时长列表--下拉
    huiLvList: (params) => get(`/fangshan/dm-new/huiLvList`, params), //货币汇率列表--下拉
    listAllCarConfig: (params) => get(`/fangshan/dm-user/listAllCarConfig`, params), //车型列表--下拉
    addressSearch: (params) => get(`/fangshan/dm-new/getAddressByGoogle`, params), //地址搜索范围 中文
    addressSearchEnglish: (params) => get(`/fangshan/dm-new/getAddressByGoogleForEnglish`, params), //地址搜索范围 英文
    selectDmDestinationList: (params) => get(`/fangshan/dm-user/selectDmDestinationList`, params), //热门目的地/旅游产品/车辆租赁 列表
    selectDmDestinationInfo: (params) => get(`/fangshan/dm-user/selectDmDestinationInfo`, params), //热门目的地/旅游产品/车辆租赁 详情
    platFormTextType: (params) => get(`/fangshan/dm-user/platFormTextType`, params), //服务协议/隐私政策/平台介绍

    //登录
    appLogin: (params) => post(`/fangshan/dm-user/appLogin`, params), //登录操作
    appRegister: (params) => post(`/fangshan/dm-user/appRegister`, params), //注册操作
    sendAccountCode: (params) => post(`/fangshan/dm-user/sendAccountCode`, params), //注册验证码发送
    forgotPassword: (params) => post(`/fangshan/dm-user/forgotPassword`, params), //忘记密码操作
    passwordBlackCode: (params) => post(`/fangshan/dm-user/passwordBlackCode`, params), //忘记密码验证码发送
    getPhoneAreaList: (params) => get(`/fangshan/dm-order/listAllCode`, params), //手机区号列表
    
    //首页
    selectBannerList: (params) => get(`/fangshan/dm-user/selectBannerList`, params), //轮播图列表
    getDestinationTree: (params) => get(`/fangshan/dm-user/getDestinationTree`, params), //目的地分类
    
    //需求定制
    selectDmCustomizedList: (params) => get(`/fangshan/dm-user/selectDmCustomizedList`, params), //定制提交历史列表
    insertDmCustomized: (params) => post(`/fangshan/dm-user/insertDmCustomized`, params), //新增需求定制
    deleteCustomized: (params) => post(`/fangshan/dm-user/deleteCustomized?id=${params}`), //删除定制消息

    //下单
    initOrderOneTwoMoney: (params) => post(`/fangshan/dm-order/initOrderOneTwoMoney`, params), //专车服务/接送机 根据地点计算价格
    calculateSingleCharterPrice: (params) => post(`/fangshan/dm-order/calculateSingleCharterPrice`, params), //单次包车 根据地点计算价格
    initOneTwoOrder: (params) => post(`/fangshan/dm-order/initOneTwoOrder`, params), //订单初始化
    setWxPay: (params) => post(`/fangshan/pay/codePay`, params), //微信支付
    setzfbPay: (params) => post(`/fangshan/pay/AlipayScanPay`, params), //支付宝支付
    setCodePayEnd: (params) => get(`/fangshan/pay/codeUserPayEnd`, params), //判断是否支付

    //vlog
    listDmInvitations: (params) => get(`/fangshan/dm-user/listDmInvitations`, params), //vlog列表
    getDmInvitationById: (params) => get(`/fangshan/dm-user/getDmInvitationById`, params), //vlog详情

    //旅游产品
    listDestinationType: (params) => get(`/fangshan/dm-user/listDestinationType`, params), //旅游产品分类

    //订单
    pageAllOrder: (params) => get(`/fangshan/dm-order/pageAllOrder`, params), //订单列表
    cancelOrder: (params) => post(`/fangshan/dm-order/cancelOrder?id=${params}`, params), //取消订单

    //个人
    getHighImportInfo: (params) => get(`/fangshan/dm-user/getDmUserDetailInfo`, params), //个人信息详情
    userInfoUp: (params) => post(`/fangshan/dm-user/userInfoUp`, params), //修改个人信息
    selectVipContextList: (params) => get(`/fangshan/dm-user/selectVipContextList`, params), //VIP权益模板内容
    userCouponList: (params) => get(`/fangshan/dm-user/userCouponList`, params), //优惠券列表
    listUserAppraise: (params) => get(`/fangshan/dm-user/listUserAppraise`, params), //客人评价列表
    listIDmPlatformCommitments: (params) => get(`/fangshan/dm-user/listIDmPlatformCommitments`, params), //平台承诺列表
    userComplaintUp: (params) => post(`/fangshan/dm-user/userComplaintUp`, params), //投诉建议操作
    updateUserPhone: (params) => post(`/fangshan/dm-user/updateUserPhone`, params), //更换手机号操作
    updateUserPhoneToSmS: (params) => post(`/fangshan/dm-user/updateUserPhoneToSmS`, params), //更换手机号验证码发送
    updateEmail: (params) => post(`/fangshan/dm-user/updateEmail`, params), //更换邮箱操作
    emailToCode: (params) => post(`/fangshan/dm-user/emailToCode`, params), //更换邮箱验证码发送
}