<template>
    <div>
        <v-header showStyle="white"></v-header>
        <div class="bg-box">
            <div class="wrap contain-box">
                <div class="head-box flex">

                    <img class="l-head" :src="info.publishImg || require('../../../public/img/avatar.png')">

                    <div class="r-head f-col-a-s">
                        <div class="titles">{{ info.invitationTitle || '' }}</div>
                        <div class="f-row-s-c">
                            <span class="names">{{ info.publishName || '' }}</span>
                            <span class="times">{{ disposeTime(info.createTime) }}</span>
                        </div>
                    </div>
                </div>
                <div class="con-box">
                    <p class="cons" v-html="info.invitationContext"></p>
                    <el-carousel :interval="2000" arrow="always" height="670px">
                        <el-carousel-item v-for="item in getImgList(info.invitationImg)" :key="item">
                            <img :src="item" alt="" height="670px" style="object-fit: cover;">
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: "vlog-details",
    data() {
        return {
            info: {}
        }
    },
    created() {
        this.getData()
    },
    methods: {
        //获取详情数据
        getData() {
            $api.getDmInvitationById({
                id: this.$route.query.id
            }).then(res => {
                if (res.code == 200) {
                    this.info = res.data || {}
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        getImgList(list) {
            return list ? list.split(',') : ''
        },
        disposeTime(time) {
            let a = time ? time.slice(0, -10) : ''
            return a.replace('T', ' ')
        },
    }
}
</script>

<style lang="scss" scoped>
.contain-box {
    padding: 30px 46px;
}

.l-head {
    flex: 0 0 120px;
    margin-right: 28px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

.r-head {
    min-height: 120px;

    .titles {
        margin-bottom: 6px;
        line-height: 36px;
        font-weight: bold;
        font-size: 26px;
        color: #333;
    }

    .names {
        margin-right: 8px;
        font-size: 14px;
        color: #333;
    }

    .times {
        font-size: 12px;
        color: #666;
    }
}

.con-box {
    margin-top: 35px;

    .cons {
        line-height: 30px;
        font-size: 16px;
        color: #333;
    }
}
</style>
