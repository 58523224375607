<template>
    <div>
        <v-header :showStyle="isPageScroll ? 'white' : ''"></v-header>
        <!-- 轮播图 -->
        <div class="ban-box">
            <v-swiper pagination navigation>
                <v-swiper-slide v-for="item in bannerList" :key="item.id">
                    <img :src="item.bannerImg" @click="goLinkBanner(item.theSkip, item.skipInfo)" />
                </v-swiper-slide>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </v-swiper>
        </div>
        <div class="content-box">

            <!-- 表单区 -->
            <div class="form-box wrap contain-box">
                <vhome-form></vhome-form>
            </div>

            <!-- 目的地 -->
            <div class="place-box wrap">
                <div class="box-top f-row-c-c">
                    <div class="list-title f-col-c-c">
                        <p class="chinese">热门目的地</p>
                        <p class="english">Popular destinations</p>
                        <div class="line"></div>
                    </div>
                </div>
                <div class="f-row-b-s">
                    <!-- 分类导航区 -->
                    <div class="l-nav">
                        <div class="nav-top">
                            <div class="nav-title">
                                <p>热门旅游城市</p>
                                <img src="../../../public/img/cate_tit.png" alt="">
                            </div>
                            <div class="nav-ul">
                                <ul class="one-ul">
                                    <li class="one-li li-box"
                                        :class="{ 'active': curCateId == item.id && curCateIndex == index }"
                                        v-for="(item, index) in placeCateList" :key="index">
                                        <div class="one-box f-row-b-c flex1">
                                            <span class="one-txt-cut flex1" @click="selectOneNav(item.id, index)">
                                                {{ item.typeName }}
                                            </span>
                                            <div v-if="item.subList && item.subList.length > 0" @click="showTwoNav(index)">
                                                <i :class="item.show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                            </div>
                                        </div>
                                        <ul v-if="item.show" class="two-ul">
                                            <template v-if="item.subList && item.subList.length > 0">
                                                <li class="two-li li-box one-txt-cut"
                                                    :class="{ 'active': curCateId == item2.id && curCateIndex == index2 }"
                                                    v-for="(item2, index2) in item.subList" :key="index2"
                                                    @click.stop="selectTwoNav(item2.id, index2)">
                                                    {{ item2.typeName }}
                                                </li>
                                            </template>
                                            <template v-else>
                                                <li class="two-li li-box">无数据</li>
                                            </template>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="l-wx">
                            <p>远山小程序</p>
                            <div class="wx-img f-row-c-c">
                                <img src="../../../public/code_wx.png" alt="">
                            </div>
                        </div>
                    </div>
                    <!-- 目的地列表区 -->
                    <div class="r-place f-row-s-c f-w">
                        <template v-if="placeList && placeList.length > 0">
                            <div class="place-item" v-for="(item, index) in placeList" :key="index"
                                @click="goPlaceDetails(item.id)">
                                <v-card :item="item"></v-card>
                            </div>
                        </template>
                        <template v-else>
                            <div class="no-content f-row-c-c flex1" style="height:200px;">暂无数据哦！</div>
                        </template>
                    </div>
                </div>
            </div>

        </div>

        <!-- 腰线图 -->
        <div class="line-box" @click="goCustomization">
            <img src="../../../public/img/home_line.png" alt="" />
        </div>

        <!-- vlog -->
        <div class="vlog-box wrap">
            <div class="box-top f-row-c-c">
                <div class="list-title f-col-c-c">
                    <p class="chinese">远山Vlog</p>
                    <p class="english">Yuanshan vlog</p>
                    <div class="line"></div>
                </div>
                <div class="list-more" @click="goMoreVlog">
                    查看更多→
                </div>
            </div>
            <div v-if="vlogList" class="f-row-s-c">
                <div v-if="vlogList.length > 0" @click="goVlogDetails(vlogList[0].id)" class="l-vlog vlog-item"
                    style="margin-right: 20px;">
                    <vhome-vlog :item="vlogList[0]" width="494px" height="468px"></vhome-vlog>
                </div>
                <div class="c-vlog f-col-b-c" style="margin-right: 20px;height: 468px;">
                    <div v-if="vlogList.length >= 2" class="vlog-item" @click="goVlogDetails(vlogList[1].id)">
                        <vhome-vlog :item="vlogList[1]" width="362px" height="224px"></vhome-vlog>
                    </div>
                    <div v-if="vlogList.length >= 3" class="vlog-item" @click="goVlogDetails(vlogList[2].id)">
                        <vhome-vlog :item="vlogList[2]" width="362px" height="224px"></vhome-vlog>
                    </div>
                </div>
                <div v-if="vlogList.length >= 4" class="r-vlog vlog-item" @click="goVlogDetails(vlogList[3].id)">
                    <vhome-vlog :item="vlogList[3]" width="296px" height="468px"></vhome-vlog>
                </div>
            </div>
        </div>

        <!-- 底部区 -->
        <vhome-foot></vhome-foot>

    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    data() {
        return {
            isPageScroll: false,//页面是否滚动
            bannerList: [], //轮播图列表
            vlogList: [], //vlog列表
            placeCateList: [],//目的地分类列表
            placeList: [],//热门目的地列表
            curCateId: '',//当前目的地分类id
            curCateIndex: '',//当前目的地分类索引
        }
    },
    created() {
        this.getBannerList()
        this.getPlaceCateList()
        this.getPlaceList()
        this.getVlogList()
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        //获取页面滚动
        handleScroll() {
            this.isPageScroll = window.scrollY > 0;
        },
        //获取轮播图列表
        getBannerList() {
            $api.selectBannerList({
                showType: 1,//1web 2移动端
            }).then(res => {
                if (res.code == 200) {
                    this.bannerList = res.data || []
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取热门目的地分类列表
        getPlaceCateList() {
            $api.getDestinationTree().then(res => {
                if (res.code == 200) {
                    this.placeCateList = res.data || []
                    this.placeCateList && this.placeCateList.unshift({
                        id: '',
                        typeName: '全部'
                    })
                    if (this.placeCateList && this.placeCateList.length > 0) {
                        this.placeCateList.forEach((item1) => {
                            item1.show = false
                        })
                        this.getPlaceList()
                    }
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取热门目的地列表
        getPlaceList() {
            $api.selectDmDestinationList({
                desName: '',
                destinationTypeId: this.curCateId,
                type: 1,
                pageNum: 1,
                pageSize: 12,
            }).then(res => {
                if (res.code == 200) {
                    this.placeList = res.rows || []
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取vlog列表
        getVlogList() {
            $api.listDmInvitations({
                pageNum: 1,
                pageSize: 4,
            }).then(res => {
                if (res.code == 200) {
                    this.vlogList = res.rows || []
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //轮播跳转链接
        goLinkBanner(isHref, address) {
            //isHref 1可以跳转 2不可跳转
            if (isHref == 1 && address && address.indexOf('http') != -1) {
                window.open(address)
            }
        },
        //更多vlog
        goMoreVlog() {
            this.$router.push('/vlogList')
        },
        //需求定制
        goCustomization() {
            this.$router.push('/customization')
        },
        //目的地详情
        goPlaceDetails(id) {
            this.$router.push({ path: '/details', query: { id, type: '1' } })
        },
        //vlog详情
        goVlogDetails(id) {
            this.$router.push({ path: '/vlogDetails', query: { id } })
        },
        //显示隐藏二级分类
        showTwoNav(i1) {
            this.placeCateList[i1].show = !this.placeCateList[i1].show
            this.$forceUpdate()
        },
        //选择一级分类
        selectOneNav(id1, i1) {
            // this.placeCateList[i1].show = !this.placeCateList[i1].show
            this.curCateId = id1
            this.curCateIndex = i1
            this.getPlaceList()
            this.$forceUpdate()
        },
        //选择二级分类
        selectTwoNav(id2, i2) {
            this.curCateId = id2
            this.curCateIndex = i2
            this.getPlaceList()
            this.$forceUpdate()
        },
    }
}
</script>

<style lang="scss" scoped>
//轮播
.ban-box {
    position: relative;

    img {
        width: 100%;
        height: 700px;
        object-fit: cover;
    }
}

.content-box {
    background: url('../../../public/img/content_bg.png') no-repeat center center/cover;
}

//公共头部
.box-top {
    position: relative;
    height: 126px;

    .list-title {
        .chinese {
            font-weight: bold;
            font-size: 36px;
            color: var(--theme-color);
        }

        .english {
            margin: 14px 0 9px;
            font-size: 14px;
            color: #333;
        }

        .line {
            width: 44px;
            height: 2px;
            background: var(--theme-color);
        }
    }

    .list-more {
        position: absolute;
        right: 0;
        top: 56px;
        font-size: 14px;
        color: #333;
        cursor: pointer;

        &:hover {
            font-weight: bold;
        }
    }
}

//form表单
.form-box {
    position: relative;
    top: -100px;
    z-index: 1;
    margin-bottom: -80px;
    padding: 0 76px;
    box-shadow: 0 0 4px 2px #ddd;
}

//热门目的地
.place-box {
    padding-bottom: 16px;

    .l-nav {
        flex: 0 0 220px;
        margin-right: 15px;
    }

    .nav-top {
        border: 1px solid #dadada;
        background: #fff;
        border-radius: 4px;

        .nav-title {
            position: relative;
            width: 100%;
            height: 62px;
            line-height: 62px;

            img {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 80px;
            }

            p {
                position: relative;
                z-index: 1;
                right: 6px;
                font-weight: bold;
                font-size: 22px;
                color: #fff;
                text-align: right;
            }
        }

        .nav-ul {
            .li-box {
                cursor: pointer;
            }

            .one-li {
                margin: 0 16px;
                line-height: 64px;
                border-bottom: 1px solid #e4e4e4;

                &:last-child {
                    border-bottom: 0;
                }

                &.active {
                    span {
                        color: var(--theme-color);
                    }
                }
            }

            .one-box {
                font-size: 16px;

                span {
                    color: #333;
                }

                i {
                    color: #b6b6b6;
                }
            }

            .two-li {
                margin: 0 -16px;
                padding: 0 22px;
                width: 218px;
                line-height: 38px;
                font-size: 14px;
                color: #b2b2b2;

                &.active {
                    color: var(--theme-color);
                    background: #f7f7f7;
                }
            }
        }
    }

    .l-wx {
        margin: 20px 0;
        padding: 0 10px 10px;
        width: 100%;
        height: 258px;
        background: url('../../../public/img/home_bg1.png') no-repeat center center/cover;

        p {
            line-height: 49px;
            font-weight: bold;
            font-size: 20px;
            color: #fff;
            text-align: center;
        }

        .wx-img {
            width: 100%;
            height: 198px;
            background: #fff;

            img {
                max-width: 90%;
                max-height: 90%;
            }
        }
    }

    .r-place {
        width: 956px;

        .place-item {
            margin: 0 20px 20px 0;
            width: 224px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            cursor: pointer;

            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}

//腰线图
.line-box {
    cursor: pointer;

    img {
        width: 100%;
        height: 248px;
        object-fit: cover;
    }
}

//远山vlog
.vlog-box {
    padding-bottom: 40px;

    .vlog-item {
        background: #ddd;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
    }
}
</style>