import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '../views/home/home.vue'
import details from '../views/home/details.vue'
import agreement from '../views/home/agreement.vue'
import customization from '../views/home/customization.vue'
import carList from '../views/home/carList.vue'
import confirm from '../views/home/confirm.vue'
import taxi from '../views/menu/taxi.vue'
import carRental from '../views/menu/carRental.vue'
import transfer from '../views/menu/transfer.vue'
import Bcar from '../views/menu/Bcar.vue'
import download from '../views/menu/download.vue'
import travelList from '../views/menu/travelList.vue'
import vlogList from '../views/vlog/list.vue'
import vlogDetails from '../views/vlog/details.vue'
import centre from '../views/personage/centre.vue'


Vue.use(VueRouter)

const routes = [
  { path: '/', component: home, meta: { title: '首页' } },
  { path: '/agreement', component: agreement, meta: { title: '用户协议' } },
  { path: '/details', component: details, name: 'details', meta: { title: '详情' } },
  { path: '/customization', component: customization, meta: { title: '定制' } },
  { path: '/carList', component: carList, meta: { title: '车辆列表' } },
  { path: '/confirm', component: confirm, meta: { title: '确认订单' } },
  { path: '/taxi', component: taxi, meta: { title: '专车服务' } },
  { path: '/carRental', component: carRental, meta: { title: '汽车租赁' } },
  { path: '/travelList', component: travelList, name: 'travelList', meta: { title: '旅游产品' } },
  { path: '/transfer', component: transfer, meta: { title: '接送机' } },
  { path: '/Bcar', component: Bcar, meta: { title: '单次包车' } },
  { path: '/download', component: download, meta: { title: '下载app' } },
  { path: '/vlogList', component: vlogList, meta: { title: '更多vlog' } },
  { path: '/vlogDetails', component: vlogDetails, meta: { title: 'vlog详情' } },
  {
    path: '/centre', component: centre, meta: { title: '个人中心' },
    children: [
      { path: 'order', component: () => import('@/views/personage/menu/order.vue'), meta: { title: '我的订单' } },
      { path: 'member', component: () => import('@/views/personage/menu/member.vue'), meta: { title: '会员中心' } },
      { path: 'discount', component: () => import('@/views/personage/menu/discount.vue'), meta: { title: '优惠券' } },
      { path: 'evaluate', component: () => import('@/views/personage/menu/evaluate.vue'), meta: { title: '客人评价' } },
      { path: 'promise', component: () => import('@/views/personage/menu/promise.vue'), meta: { title: '平台承诺' } },
      { path: 'about', component: () => import('@/views/personage/menu/about.vue'), meta: { title: '关于平台' } },
      { path: 'set', component: () => import('@/views/personage/menu/set.vue'), meta: { title: '设置' } },
      { path: 'complaint', component: () => import('@/views/personage/menu/complaint.vue'), meta: { title: '投诉建议' } },
      { path: 'setMessage', component: () => import('@/views/personage/menu/setMessage.vue'), meta: { title: '修改个人信息' } }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
