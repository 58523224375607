<template>
  <div>
    <v-header showStyle="white"></v-header>
    <div class="wrap contain-box loading-box">
      <template v-if="dataList && dataList.length > 0">
        <div class="list-box f-row-s-c f-w">
          <div class="list-item" v-for="(item, index) in dataList" :key="index" @click="goDetails(item.id)">
            <v-card :item="item" showPage="car"></v-card>
          </div>
        </div>
        <div class="paging">
          <el-pagination background layout="prev, pager, next" :total="dataCount" :page-size="pageSize"
            :page-count="Math.ceil(dataCount / 3)" @current-change="changePage" :current-page="pageNum">
          </el-pagination>
        </div>
      </template>
      <template v-else>
        <div class="no-content f-row-c-c" style="height: 500px;">暂无数据哦！</div>
      </template>
    </div>
  </div>
</template>
  
<script>
import $api from '@/api/api.js'
export default {
  data() {
    return {
      dataList: [],//列表
      dataCount: 1, //总数据数量
      pageNum: 1,
      pageSize: 10,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    //获取汽车租赁列表
    getList() {
      const loading = this.$loading('.loading-box', '加载中', true)
      $api.selectDmDestinationList({
        desName: this.$route.query.name || '',
        destinationTypeId: '',
        type: 3,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then(res => {
        loading.close()
        if (res.code == 200) {
          this.dataList = res.rows || []
          this.dataCount = res.total || 0
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //切换分页
    changePage(e) {
      this.pageNum = e
      this.getList()
    },
    //去详情
    goDetails(id) {
      this.$router.push({ path: '/details', query: { id, type: '3' } })
    },
  }
}
</script>
  
<style lang="scss" scoped>
.contain-box {
  margin-top: 116px;
  padding: 20px;
}

.list-box {
  .list-item {
    margin: 0 10px 20px 0;
    width: 224px;
    height: 258px;
    background: #fff;
    border: 1px solid #e6e8e7;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;

    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}
</style>