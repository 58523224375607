<template>
    <div>
        <v-header showStyle="white"></v-header>
        <div class="bg-box">
            <div class="wrap contain-box loading-box">
                <template v-if="dataList && dataList.length > 0">
                    <div class="list-box">
                        <div class="list-item" v-for="(item, index) in dataList" :key="index" @click="goDetails(item.id)">
                            <vvlog-item :item="item"></vvlog-item>
                        </div>
                    </div>
                    <div class="paging" style="padding: 20px 0;">
                        <el-pagination background layout="prev, pager, next" :total="dataCount" :page-size="pageSize"
                            :page-count="Math.ceil(dataCount / 3)" @current-change="changePage" :current-page="pageNum">
                        </el-pagination>
                    </div>
                </template>
                <template v-else>
                    <div class="no-content f-row-c-c">暂无数据哦！</div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'vlog-list',
    data() {
        return {
            dataList: [],//列表
            dataCount: 1, //总数据数量
            pageNum: 1,
            pageSize: 5,
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        //获取列表
        getList() {
            const loading = this.$loading('.loading-box', '加载中', true)
            $api.listDmInvitations({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }).then(res => {
                loading.close()
                if (res.code == 200) {
                    this.dataList = res.rows || []
                    this.dataCount = res.total || 0
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //切换分页
        changePage(e) {
            this.pageNum = e
            this.getList()
        },
        //去详情
        goDetails(id) {
            this.$router.push({ path: '/vlogDetails', query: { id } })
        },
    }
}
</script>

<style lang="scss" scoped>
.contain-box {
    padding: 0 60px;
}

.list-item {
    padding: 40px 0;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    user-select: none;
}
</style>