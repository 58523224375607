<template>
    <div>
        <v-header showStyle="white"></v-header>

        <div class="bg-box">
            <div class="wrap contain-box">

                <!-- 车辆信息 -->
                <div class="car-box">
                    <div class="common-title">车辆信息</div>
                    <div class="carList">
                        <template v-if="dataList && dataList.length > 0">
                            <el-radio-group class="w100" v-model="selectCarId" @change="changeCar">
                                <div class="item" v-for="item in dataList" :key="item.id">
                                    <label class="f-row-s-c">
                                        <el-radio :label="item.id"></el-radio>
                                        <div class="info flex1">
                                            <v-car :item="item"></v-car>
                                        </div>
                                    </label>
                                    <!-- <el-input-number class="input-num" v-model="item.num" :min="1"></el-input-number> -->
                                </div>
                            </el-radio-group>
                            <div v-if="dataCount > 4" class="paging" style="padding:20px 0;">
                                <el-pagination background layout="prev, pager, next" :total="dataCount"
                                    :page-size="pageSize" :page-count="Math.ceil(dataCount / 3)"
                                    @current-change="changePage" :current-page="pageNum">
                                </el-pagination>
                            </div>
                        </template>
                        <template v-else>
                            <div class="no-content f-row-c-c" style="height: 200px;">暂无数据！</div>
                        </template>
                    </div>
                </div>
                <!-- 平台服务 -->
                <div class="serve-box" style="margin-top:20px;">
                    <div class="common-title">平台服务</div>
                    <div class="serveList">
                        <template v-if="serveList && serveList.length > 0">
                            <el-checkbox-group class="f-row-s-c f-w" v-model="selectServeArr" @change="changeServe">
                                <div class="items f-row-s-c" v-for="item in serveList" :key="item.value">
                                    <el-checkbox :label="item.value"></el-checkbox>
                                    <div class="ins f-row-s-c">
                                        <img v-if="item.serviceName == '举牌接机'"
                                            src="../../../public/img/clxx_icon_jpjj.png" />
                                        <img v-if="item.serviceName == '儿童椅'" src="../../../public/img/clxx_icon_ety.png" />
                                        <img v-if="item.serviceName != '儿童椅' && item.serviceName != '举牌接机'"
                                            src="../../../public/img/clxx_icon_qtfw.png" />

                                        <div>{{ item.serviceName }} <span>￥{{ item.servicePrice }}</span></div>

                                        <el-input-number class="input-num" v-if="item.serviceName == '儿童椅'"
                                            v-model="item.num" :min="1"></el-input-number>
                                    </div>
                                </div>
                            </el-checkbox-group>
                        </template>
                        <template v-else>
                            <div class="no-content f-row-c-c" style="height: 200px;">暂无数据！</div>
                        </template>
                    </div>
                </div>
                <!-- 提交 -->
                <div class="operate-box">
                    <div class="price-box f-row-b-c">
                        <div class="l-price f-row-s-c">
                            价格：<span class="nums">￥<span>{{ SumPrice }}</span></span>
                        </div>
                        <div class="r-price f-row-c-c" @click="handleSubmit">确认订单</div>
                    </div>
                </div>

            </div>
        </div>

        <!-- 确认订单弹窗 -->
        <vorder-dialog ref="orderChild" :curType="curOrderType" :isShow="orderDialog" :infos="orderInfo"
            @closeDialog="closeOrderDialog" @confirmPay="confirmPay"></vorder-dialog>

        <!-- 支付二维码弹窗 -->
        <vpay-dialog ref="payChild" :isShow="payDialog" :infos="codeInfo" :payWay="currentPay"
            @closeDialog="closePayDialog"></vpay-dialog>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'carList',
    data() {
        return {
            orders: {},//订单信息
            curOrderType: '4',//当前订单类型 4专车 6包车

            selectCarId: '',//选中的车型id
            selectCarInfo: {},//选中的车型
            selectServeArr: [],//选中的服务id列表

            serveList: [],//服务列表
            dataList: [],//车辆列表
            dataCount: 1, //总数据数量
            pageNum: 1,
            pageSize: 4,

            totalPrice: 0.00,//总价

            //显示弹窗--确认订单
            orderDialog: false,
            orderInfo: {},//确认订单信息

            //显示弹窗--扫码支付
            payDialog: false,
            currentPay: '1',//支付方式
            codeInfo: {
                id: '',
                urlCode: '',
            },
        }
    },
    created() {
        this.curOrderType = this.$route.query.type
        if (localStorage.getItem('orderInfo')) {
            this.orders = JSON.parse(decodeURIComponent(localStorage.getItem('orderInfo')))
            console.log('orderInfo', this.orders)
        }
        this.getCarList()
        this.getServeList()
    },
    computed: {
        //车型价格
        CarPrice() {
            let newObj = {}
            let price = 0
            newObj = this.dataList.find((item) => this.selectCarId == item.id)
            if (newObj) {
                price = newObj.num * newObj.unitPrice
            }
            return price
        },
        //服务价格
        ServePrice() {
            let newList = this.serveList.filter((item) => {
                return this.selectServeArr.includes(item.value)
            })
            let price = 0
            newList.map(item => {
                price += item.num * item.servicePrice
            })
            return price
        },
        //总价
        SumPrice() {
            this.totalPrice = (this.CarPrice + this.ServePrice).toFixed(2)
            return (this.CarPrice + this.ServePrice).toFixed(2)
        },
    },
    methods: {
        //获取车型列表 curOrderType 4专车 5包车
        getCarList() {
            const loading = this.$loading('.loading-box', '加载中', true)
            let dataParams = {}
            let params = {}
            dataParams = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }
            if (this.curOrderType == 4) {
                params = {
                    originsAddress: this.orders.setOutAddress || '',
                    destinationsAddress: this.orders.arriveOutAddress || '',
                    appointmentTime: this.orders.appointmentTime || '',
                }
            } else if (this.curOrderType == 6) {
                params = {
                    originsAddress: this.orders.setOutAddress || '',
                    hour: this.orders.vehicleDuration || ''
                }
            }
            Object.assign(dataParams, params)
            $api[this.curOrderType == 4 ? 'pageAllUserCarByAddress' : 'pageAllUserCarByTime'](dataParams).then(res => {
                loading.close()
                if (res.code == 200) {
                    if (res.rows && res.rows.length > 0) {
                        res.rows.forEach(item => {
                            item.num = 1
                        })
                    }
                    this.dataList = res.rows || []
                    this.dataCount = res.total || 0
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取平台服务列表
        getServeList() {
            $api.pageAllCarService().then(res => {
                if (res.code == 200) {
                    res.rows && res.rows.forEach((item, index) => {
                        item.num = 1
                        item.value = index + 1
                    })
                    this.serveList = res.rows
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //切换分页
        changePage(e) {
            this.pageNum = e
            this.getList()
        },
        //选择车型
        changeCar(e) {
            this.selectCarId = e
            this.selectCarInfo = this.dataList.find((item, index) => item.id == e)
        },
        //选择服务
        changeServe(e) {
            this.selectServeArr = e
        },
        //立刻出发--打开确认订单
        handleSubmit() {
            if (!this.selectCarId) {
                this.$message.warning('请选择车辆')
                return
            }
            //服务
            let serveInfoArr = []
            let otherServiceIds = []
            let theRaising = 1//举牌接机（1不需要 2需要）
            let theBaby = 0//儿童座椅数量
            this.selectServeArr.forEach(item => {
                this.serveList.forEach(i => {
                    if (item == i.value) {
                        serveInfoArr.push(i)
                    }
                })
            })
            //举牌接机
            // let serve1 = serveInfoArr.findIndex(i => i.serviceName == '举牌接机')
            // theRaising = serve1 != -1 ? 2 : 1
            //儿童椅
            let serve2 = serveInfoArr.findIndex(i => i.serviceName == '儿童椅')
            let obj2 = {}
            obj2 = serveInfoArr.find(i => i.serviceName == '儿童椅')
            theBaby = serve2 != -1 ? obj2.num : 0
            //其他服务
            let arr2 = []
            arr2 = serveInfoArr.filter(i => i.id != null)
            let arr3 = []
            arr2.forEach(i => {
                arr3.push(i.id)
            })
            otherServiceIds = arr3.join(',')

            this.orderInfo = {}
            this.orderInfo = {
                orderSource: 'PC',
                orderTypeCode: this.orders.orderTypeCode,
                setOutAddress: this.orders.setOutAddress,
                arriveOutAddress: this.orders.arriveOutAddress,
                appointmentTime: this.orders.appointmentTime,
                vehicleDuration: this.orders.vehicleDuration,
                cardId: this.selectCarId,
                cardName: this.selectCarInfo.carTypeName,
                allMoney: this.totalPrice,

                //theRaising: theRaising,//举牌接机（1不需要 2需要）
                theBaby: theBaby,//儿童座椅数量
                otherServiceIds: otherServiceIds,//其他服务
            }

            console.log(999, this.orderInfo)
            this.orderDialog = true
        },
        //关闭弹窗--确认订单
        closeOrderDialog() {
            this.orderDialog = false
        },
        //关闭弹窗--扫码支付
        closePayDialog() {
            this.payDialog = false
        },
        //确认弹窗--打开扫码支付弹窗
        confirmPay(backObj) {
            this.currentPay = backObj.payWay
            let params = {
                orderId: backObj.orderId,
                orderType: 2, //1企业 2用户
            }
            //获取支付信息
            $api[backObj.payWay == 1 ? 'setzfbPay' : 'setWxPay'](params).then(res => {
                if (res.code == 200) {
                    this.codeInfo = res.data || {}
                    this.$refs.payChild.getOpenDialog()
                    this.payDialog = true
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
//复选框
::v-deep .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: 1px solid #CDCDCD;
    border-radius: 100%;
}

::v-deep .el-checkbox__inner::after {
    height: 10px;
    left: 7px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
}

::v-deep .el-checkbox__label {
    display: none;
}

//计步器
::v-deep .el-input-number__decrease {
    background: transparent;
}

::v-deep .el-input-number__increase {
    background: transparent;
}

.contain-box {
    padding: 35px 46px;
}

.input-num {
    height: 32px;
    line-height: 30px;
}

.common-title {
    padding: 0 14px;
    font-weight: bold;
    font-size: 26px;
    color: #2E2D2D;
    border-left: 4px solid var(--theme-color);
}

.carList {
    ::v-deep .el-radio__label {
        display: none !important;
    }

    .item {
        position: relative;

        .input-num {
            position: absolute;
            right: 0;
            bottom: 40px;
        }

        .info {
            margin-left: 20px;
            padding: 40px 0;
            border-bottom: 1px solid #E4E4E4;
        }
    }
}

.box {
    background: #fff;
    height: calc(100vh - 138px);
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
    padding: 20px;
}

.serveList {
    margin-top: 24px;

    ::v-deep .el-checkbox {
        margin-top: 4px;
    }

    .items {
        margin-right: 40px;
        gap: 14px;
    }

    .ins {
        gap: 8px;
        font-size: 18px;

        img {
            width: auto;
            height: 16px;
        }

        span {
            font-size: 20px;
            color: var(--price-color);
        }
    }
}

.operate-box {
    padding: 50px 0 100px;
    width: 632px;
}

.price-box {
    height: 74px;
    background: #f3f3f3;
    border-radius: 4px;
    overflow: hidden;

    .l-price {
        padding-left: 18px;
        font-size: 18px;
        color: #333;

        .nums {
            font-size: 14px;
            color: #fa692a;

            span {
                font-weight: bold;
                font-size: 20px;
            }
        }
    }

    .r-price {
        width: 220px;
        height: 100%;
        font-size: 22px;
        color: #fff;
        background: var(--theme-color);
        cursor: pointer;
    }
}
</style>