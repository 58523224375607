<template>
    <div>
        <v-header showStyle="white"></v-header>
        <div class="bg-box">
            <div class="wrap f-row-b-s">

                <!-- 分类导航区 -->
                <div class="l-nav">
                    <div class="nav-title">
                        <p>热门旅游城市</p>
                        <img src="../../../public/img/cate_tit.png" alt="">
                    </div>
                    <div class="nav-ul">
                        <ul class="one-ul">
                            <li class="one-li li-box" :class="{ 'active': curCateId == item.id && curCateIndex == index }"
                                v-for="(item, index) in cateList" :key="index">
                                <div class="one-box f-row-b-c flex1">
                                    <span class="one-txt-cut flex1" @click="selectOneNav(item.id, index)">
                                        {{ item.typeName }}
                                    </span>
                                    <div v-if="item.subList && item.subList.length > 0" @click="showTwoNav(index)">
                                        <i :class="item.show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                    </div>
                                </div>
                                <ul v-if="item.show" class="two-ul">
                                    <template v-if="item.subList && item.subList.length > 0">
                                        <li class="two-li li-box one-txt-cut"
                                            :class="{ 'active': curCateId == item2.id && curCateIndex == index2 }"
                                            v-for="(item2, index2) in item.subList" :key="index2"
                                            @click.stop="selectTwoNav(item2.id, index2)">
                                            {{ item2.typeName }}
                                        </li>
                                    </template>
                                    <template v-else>
                                        <li class="two-li li-box">无数据</li>
                                    </template>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 列表区 -->
                <div class="r-list flex1 contain-box loading-box">
                    <template v-if="dataList && dataList.length > 0">
                        <div class="list-box f-row-s-c f-w">
                            <div class="list-item" v-for="(item, index) in dataList" :key="index"
                                @click="goDetails(item.id)">
                                <v-card :item="item"></v-card>
                            </div>
                        </div>
                        <div class="paging">
                            <el-pagination background layout="prev, pager, next" :total="dataCount" :page-size="pageSize"
                                :page-count="Math.ceil(dataCount / 3)" @current-change="changePage" :current-page="pageNum">
                            </el-pagination>
                        </div>
                    </template>
                    <template v-else>
                        <div class="no-content f-row-c-c" style="height: 500px;">暂无数据哦！</div>
                    </template>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'travelList',
    data() {
        return {
            dataList: [],//列表
            dataCount: 1, //总数据数量
            pageNum: 1,
            pageSize: 12,

            cateList: [],//分类列表
            curCateId: '',//当前分类id
            curCateIndex: '',//当前目的地分类索引
        }
    },
    mounted() {
        this.getCateList()
    },
    methods: {
        //获取旅游产品分类列表
        getCateList() {
            $api.listDestinationType().then(res => {
                if (res.code == 200) {
                    this.cateList = res.data || []
                    if(this.cateList){
                        this.cateList.unshift({
                            id:'',
                            typeName:'全部'
                        })
                    }
                    this.getList()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取旅游产品列表
        getList() {
            const loading = this.$loading('.loading-box', '加载中', true)
            $api.selectDmDestinationList({
                desName: this.$route.query.name || '',
                destinationTypeId: this.curCateId,
                type: 2,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }).then(res => {
                loading.close()
                if (res.code == 200) {
                    this.dataList = res.rows || []
                    this.dataCount = res.total || 0
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //显示隐藏二级分类
        showTwoNav(i1) {
            this.cateList[i1].show = !this.cateList[i1].show
            this.$forceUpdate()
        },
        //选择一级分类
        selectOneNav(id1, i1) {
            // this.cateList[i1].show = !this.cateList[i1].show
            this.curCateId = id1
            this.curCateIndex = i1
            this.getList(id1)
            this.$forceUpdate()
        },
        //选择二级分类
        selectTwoNav(id2, i2) {
            this.curCateId = id2
            this.curCateIndex = i2
            this.getList(id2)
            this.$forceUpdate()
        },
        //切换分页
        changePage(e) {
            this.pageNum = e
            this.getList()
        },
        //去详情
        goDetails(id) {
            this.$router.push({ path: '/details', query: { id, type: '2' } })
        },
    }
}
</script>

<style lang="scss" scoped>
.contain-box {
    padding: 20px;
}

.l-nav {
    flex: 0 0 220px;
    margin-right: 15px;
    border: 1px solid #dadada;
    background: #fff;
    border-radius: 4px;

    .nav-title {
        position: relative;
        width: 100%;
        height: 62px;
        line-height: 62px;

        img {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80px;
        }

        p {
            position: relative;
            z-index: 1;
            right: 6px;
            font-weight: bold;
            font-size: 22px;
            color: #fff;
            text-align: right;
        }
    }

    .nav-ul {

        .li-box {
            cursor: pointer;
        }

        .one-li {
            margin: 0 16px;
            line-height: 64px;
            border-bottom: 1px solid #e4e4e4;

            &:last-child {
                border-bottom: 0;
            }

            &.active {
                span {
                    color: var(--theme-color);
                }
            }
        }

        .one-box {
            font-size: 16px;

            span {
                color: #333;
            }

            i {
                color: #b6b6b6;
            }
        }

        .two-li {
            margin: 0 -16px;
            padding: 0 22px;
            width: 218px;
            line-height: 38px;
            font-size: 14px;
            color: #b2b2b2;

            &.active {
                color: var(--theme-color);
                background: #f7f7f7;
            }
        }
    }
}

.list-box {
    .list-item {
        margin: 0 10px 20px 0;
        width: 223px;
        height: 258px;
        background: #fff;
        border: 1px solid #e6e8e7;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;

        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}
</style>